import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import Loadable from '@loadable/component';
import Layout, { AppContext } from '../components/layout';
import SEO from '../components/seo';

import btnHome from '../images/btn-home.svg';
import btnPlanning from '../images/btn-planning.svg';

const Nav = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	z-index: 10;
	
	& > .left-btn {
		&:before {
			position: absolute;
			top: -30px;
			left: -34px;
			width: 68px;
			height: 68px;
			background: #FAFAFA;
			content: '';
			transform: rotateZ(45deg);
		}
		
		&:after {
			position: absolute;
			top: -30px;
			left: 70px;
			width: 68px;
			height: 68px;
			background: #FAFAFA;
			content: '';
			transform: rotateZ(45deg);
		}
		
		.btn {
			position: absolute;
			top: 22px;
			left: 18px;
			width: 68px;
			height: 68px;
			content: '';
			transform: rotateZ(45deg);
			background-color: #100F21;
			transition: background 0.2s ease-in-out;
			
			.img {
				margin: 8px 12px 12px 8px;
				width: 48px;
				height: 48px;
				cursor: pointer;
				transform: rotateZ(-45deg);
				background-color: #FAFAFA;
			    -webkit-mask: url(${btnHome}) no-repeat center;
			    mask: url(${btnHome}) no-repeat center;
				transition: background 0.2s ease-in-out;
			}
			
			&:hover {
				background: #FAFAFA;
				
				.img {
					background-color: #100F21;
				}
			}
		}
	}
	
	& > .right-btn {
		&:before {
			position: absolute;
			top: -30px;
			right: -34px;
			width: 68px;
			height: 68px;
			background: #FAFAFA;
			content: '';
			transform: rotateZ(45deg);
		}
		
		&:after {
			position: absolute;
			top: -30px;
			right: 70px;
			width: 68px;
			height: 68px;
			background: #FAFAFA;
			content: '';
			transform: rotateZ(45deg);
		}
		
		.btn {
			position: absolute;
			top: 22px;
			right: 18px;
			width: 68px;
			height: 68px;
			content: '';
			transform: rotateZ(45deg);
			background-color: #100F21;
			transition: background 0.2s ease-in-out;
			
			.img {
				margin: 8px 12px 12px 8px;
				width: 48px;
				height: 48px;
				cursor: pointer;
				transform: rotateZ(-45deg);
				background-color: #FAFAFA;
			    -webkit-mask: url(${btnPlanning}) no-repeat center;
			    mask: url(${btnPlanning}) no-repeat center;
				transition: background 0.2s ease-in-out;
			}
			
			&:hover {
				background: #FAFAFA;
				
				.img {
					background-color: #100F21;
				}
			}
		}
	}
`;

const Visit = Loadable(() => import('../components/visit'));

const VisitePage = () => {
	const { setSchedulesModal } = useContext(AppContext);
	const [ show, setShow ] = useState(false);

	useEffect(() => {
		setShow(true);
	}, []);

	return (
		<>
			<SEO title="Visite virtuelle"/>

			<Nav>
				<div className="left-btn">
					<Link to="/">
						<div className="btn">
							<div className="img" />
						</div>
					</Link>
				</div>

				<div className="right-btn" onClick={() => setSchedulesModal(true)}>
					<div className="btn">
						<div className="img" />
					</div>
				</div>
			</Nav>

			{show && (
				<Visit />
			)}
		</>
	);
};

export default () => (
	<Layout>
		<VisitePage />
	</Layout>
);